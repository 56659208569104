<template>
  <!-- 分类浏览接口对接完成 -->
  <div class="kindhead">
    <Headernav></Headernav>
    <Retrievalnav></Retrievalnav>
    <div class="kindbody" style="display: flex">
      <ClassificationSide @setRes="fun"></ClassificationSide>
      <div class="kindright">
        <div class="kindtitle">
          <div class="titleleft">
            <p class="kindline"></p>
            <p class="book" :class="this.book == '' ? 'book' : 'bookmore'">
              {{ book }}
            </p>
            <p class="brownp">
              资源类型:<span class="brownspan">{{ activename }}</span>
            </p>
            <p class="brownp">
              分类:<span class="brownspan">{{ childname }}</span>
            </p>
            <p class="brownp">
              共有<span class="brownspa">{{ total }}</span
              >条记录
            </p>
          </div>
          <div class="kindtab">
            <p class="school">资源类型:</p>
            <el-tabs
              v-model="activename"
              @tab-click="kindchoose"
              class="tabsss"
              v-for="(item, index) in editableTabs"
              :key="index"
            >
              <el-tab-pane
                style="width: 40px"
                :label="item.NAME"
                :name="item.NAME"
              ></el-tab-pane>
            </el-tabs>
          </div>
          <div class="tables">
            <el-table
              :data="tableData"
              stripe
              style="width: 100%; border: solid 1px #ebebeb"
              @row-click="rowClick"
              @cell-mouse-enter="classEnter"
              @cell-mouse-leave="classLeave"
            >
              <el-table-column
                type="index"
                :index="indexChange"
                label="序号"
                width="170"
              >
              </el-table-column>
              <el-table-column prop="title" label="题名" width="170">
                <template slot-scope="scope">
                  <div class="fensi">{{ scope.row.title }}</div>
                </template>
              </el-table-column>
              <el-table-column prop="author" label="责任者" width="170">
                <template slot-scope="scope">
                  <div class="fense">{{ scope.row.author }}</div>
                </template>
              </el-table-column>
              <el-table-column prop="publish" label="出版信息" width="170">
                <template slot-scope="scope">
                  <div class="fense">{{ scope.row.publish }}</div>
                </template>
              </el-table-column>
              <el-table-column prop="isbn" width="168" label="索书号">
              </el-table-column>
            </el-table>
          </div>
          <div class="blockfenyeqi">
            <el-pagination
              v-if="total / pagesize > 1"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page.sync="currentPage1"
              :page-size="pagesize"
              layout="prev, pager, next"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Api from "../api/api_classification";
import Headernav from "../components/headernav.vue";
import Footer from "../components/footer.vue";
import Retrievalnav from "../components/retrievalnav.vue";
import ClassificationSide from "../components/ClassificationSide.vue";
export default {
  name: "CategoryBrowsing", //分类浏览
  data() {
    return {
      currentPage1: 1,
      pagesize: 10,
      total: "",
      book: "",
      activename: "",
      childname: "",
      tableData: [],
      editableTabs: [],
      allLength:""
    };
  },
  components: {
    Headernav,
    Footer,
    Retrievalnav,
    ClassificationSide,
  },
  created() {},
  methods: {
    classEnter(row, column, cell, event) {
      this.book = row.title;
    },
    classLeave() {
      this.book = "";
    },
    indexChange(index) {

      return (this.currentPage1 - 1) * this.pagesize + index + 1;
    },
    kindchoose(name, event) {
      this.activename = name.label;
       this.currentPage1 = 1;
      if(this.childname=="全部("+this.allLength.data.length+")"){
           let params = {
        pageNum: this.currentPage1,
        pageSize: this.pagesize,
        cirtypename: this.activename,
      };
      Api.getLook(params).then((res) => {
        this.tableData = res.rows;
        this.total = res.total;
      });
      }else{
         let params = {
        className: this.childname,
        pageNum: this.currentPage1,
        pageSize: this.pagesize,
        cirtypename: this.activename,
      };
      Api.getLook(params).then((res) => {
        this.tableData = res.rows;
        this.total = res.total;
      });
      }
     
    },

    rowClick(row, column) {
      this.$router.push({
        path: "/Retrievaldetails",
        query: {
          id: row.id,
        },
      });
    },
    handleSizeChange(val) {
    },
    handleCurrentChange(val) {
      if (this.childname == "全部("+this.allLength.data.length+")") {
        let params = {
          pageNum: this.currentPage1,
          pageSize: this.pagesize,
           cirtypename: this.activename,
        };
        Api.getLook(params).then((res) => {
          this.tableData = res.rows;
          this.total = res.total;
        });
      } else {
        let params = {
          className: this.childname,
          pageNum: this.currentPage1,
          pageSize: this.pagesize,
          cirtypename: this.activename,
        };
        Api.getLook(params).then((res) => {
          this.tableData = res.rows;
          this.total = res.total;
        });
      }
    },
    async fun(name) {
    this.allLength =await Api.classification();
      let res = await Api.getBtype();
      this.editableTabs = res.data;
      this.activename = res.data[0].NAME;
      this.currentPage1 = 1;
      this.childname = name;
      if (name == "全部("+this.allLength.data.length+")") {
        let params = {
          pageNum: this.currentPage1,
          pageSize: this.pagesize,
           cirtypename: this.activename,
        };
        let data = await Api.getLook(params);
        this.tableData = data.rows;
        this.total = data.total;
      } else {
        let params = {
          className: this.childname,
          pageNum: this.currentPage1,
          pageSize: this.pagesize,
          cirtypename: this.activename,
        };
        let data = await Api.getLook(params);
        this.tableData = data.rows;
        this.total = data.total;
      }
    },
  },
};
</script>

<style  scoped>
.kindhead {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #fff;
}
.kindbody {
  display: flex;
  justify-content: center;
}

.kindline {
  width: 3px;
  height: 21px;
  background-color: #ff7701;
}
.titleleft {
  /* display: flex;
  align-items: center; */
  width: 100%;
  display: flex;
  align-items: center;
  height: 52px;
  margin-bottom: 9px;
}
.book {
  margin-left: 10px;
  color: #ff7701;
  font-size: 18px;
  
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.bookmore {
  width: 120px;
  margin-left: 10px;
  color: #ff7701;
  font-size: 18px;
  
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.kindtitle {
  border-bottom: 1px solid #eeeeee;
  width: 851px;
  height: 52px;
}
.kindright {
  margin-left: 56px;
  display: flex;
  flex-direction: column;
}
.brownp {
  font-size: 14px;
  color: #000;
  
  padding-left: 15px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.brownspan {
  font-size: 14px;
  color: #ff0000;
  
  padding-left: 15px;
}
.brownspa {
  font-size: 14px;
  color: #ff0000;
  
}
.kindtab {
  display: flex;
}
.school {
  font-size: 14px;
  color: #000000;
  
}
.tabss {
  padding-left: 23px;
  margin-top: 9px;
}
.tabss .el-tabs__nav-wrap::after {
  background-color: transparent;
}
::v-deep .tabss .el-tabs__item {
  /* padding: 0 19px; */
  width: 40px;
  text-align: center;
  margin-left: 20px;
  height: 30px;
  box-sizing: border-box;
  line-height: 30px;
  display: inline-block;
  list-style: none;
  font-size: 14px;
  font-weight: 500;
  /* color: #303133; */
  position: relative;
  color: #000;
  background: #fff;
}
::v-deep .tabss .el-tabs__item.is-active {
  /* padding: 0 19px; */
  padding: 0 1px;
  width: 40px;
  /* text-align: center; */
  /* margin-left: 20px; */
  height: 30px;
  box-sizing: border-box;
  line-height: 30px;
  display: inline-block;
  list-style: none;
  font-size: 14px;
  font-weight: 500;
  /* color: #303133; */
  position: relative;
  color: white;
  background: #ff7701;
  border-radius: 3px;
}
::v-deep .tabss .el-tabs__active-bar {
  background: transparent;
}
::v-deep .tabss .el-tabs__nav-wrap::after {
  background: transparent;
}
.tabsss {
  padding-left: 29px;
  margin-top: 9px;
}
.tabsss .el-tabs__nav-wrap::after {
  background-color: transparent;
}
::v-deep .tabsss .el-tabs__item {
  /* padding: 0 19px; */
  width: auto;
  text-align: center;
  margin-left: 20px;
  height: 30px;
  box-sizing: border-box;
  line-height: 30px;
  display: inline-block;
  list-style: none;
  font-size: 14px;
  font-weight: 500;
  /* color: #303133; */
  position: relative;
  color: #000;
  background: #fff;
}
::v-deep .tabsss .el-tabs__item.is-active {
  /* padding: 0 19px; */
  padding: 0 1px;
  width: auto;
  /* text-align: center; */
  /* margin-left: 20px; */
  height: 30px;
  box-sizing: border-box;
  line-height: 30px;
  display: inline-block;
  list-style: none;
  font-size: 14px;
  font-weight: 500;
  /* color: #303133; */
  position: relative;
  color: white;
  background: #ff7701;
  border-radius: 3px;
}
::v-deep .tabsss .el-tabs__active-bar {
  background: transparent;
}
::v-deep .tabsss .el-tabs__nav-wrap::after {
  background: transparent;
}
::v-deep .tabsss #tab-全部 {
  width: 40px;
}
::v-deep .tables .el-table th > .cell {
  text-align: center;
}
::v-deep .tables .el-table .cell {
  text-align: center;
}
::v-deep .tables .el-table th {
  padding: 0;
  height: 35px;
  background-color: #ff9234;
  color: white;
  
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
}
::v-deep .tables .el-table td,
.el-table th {
  padding: 0;
}
::v-deep .tables .el-table__row {
  height: 42px;
  font-size: 12px;
}
::v-deep .tables .el-table td,
.el-table th.is-leaf {
  height: 40px;
}
.fensi {
  color: #ffa3a3 !important;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.fense {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

::v-deep .tables .el-table__row:hover {
  color: #ff9234 !important;
}
::v-deep .tables .el-table__row:hover .fensi {
  color: #ff9234 !important;
}
::v-deep .tables .el-table {
  
  color: black;
}
::v-deep
  .tables
  .el-table--striped
  .el-table__body
  tr.el-table__row--striped
  td {
  background: white;
}
::v-deep .blockfenyeqi .el-pagination {
  text-align: end;
  margin-top: 10px;
}
::v-deep .tables .el-table th > .cell {
  color: #fff;
}

/* .kindhead .footer {
  position: fixed;
} */
</style>

